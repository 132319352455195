import React, { useEffect, useState } from 'react';
import './styles.scss';
import Tippy from '@tippyjs/react';
import { withAlert } from 'react-alert';

import { observer } from 'mobx-react-lite';
import { ReactComponent as SurgeIcon } from '../../assets/images/flash-icon.svg';
import SurgeModal from '../modals/surgeModal';
import homeStore from '../../stores/homeStore';

const Surge = observer(function Surge({ order, alert }) {
  const [showSurgeModal, setShowSurgeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [surgeValue, setSurgeValue] = useState('');
  const orderCode = order.order_code;

  useEffect(() => {
    setSurgeValue(order.surge?.kitchen_surge_estimated_time ?? '');
  }, [order.surge]);

  const updateDeliveryFactors = async (val) => {
    setLoading(true);
    const body = {
      kitchen_surge: val === 0 ? '0.0' : '1.0',
      kitchen_surge_estimated_time: `${val}`,
      order_id: order.id
    };
    const message = orderCode + ' surge updated successfully!';
    try {
      await homeStore.postDeliveryValues(alert, body, message);
      setSurgeValue(`${val}`);
    } finally {
      setLoading(false);
      setShowSurgeModal(false);
    }
  };
  return (
    <div className="absolute bottom-32 right-10 z-50">
      <Tippy content={surgeValue ? `${surgeValue} minutes` : `Manage ${orderCode} surge`}>
        <button
          onClick={() => setShowSurgeModal(true)}
          className={`justify-center items-center flex text-white mx-1 shadow-lg ${
            surgeValue ? 'bg-red-5' : 'bg-gray-4'
          } pointer`}
          style={{
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            right: '30px',
            top: '400px',
            zIndex: 49
          }}>
          <SurgeIcon />
        </button>
      </Tippy>

      {surgeValue ? (
        <div className="justify-center items-center flex text-white bg-green-5 absolute top-0 right-0 w-6 h-6 border border-white rounded-full z-50">
          {surgeValue}
        </div>
      ) : null}

      {showSurgeModal && (
        <SurgeModal
          show={showSurgeModal}
          loading={loading}
          value={surgeValue || 0}
          orderCode={orderCode}
          onClose={() => setShowSurgeModal(false)}
          onConfirm={updateDeliveryFactors}
        />
      )}
    </div>
  );
});

export default withAlert()(Surge);
