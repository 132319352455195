import { FC, SVGProps } from 'react';
import { ReactComponent as DispatchIcon } from './icons/box-tick.svg';
import { ReactComponent as LiveOrderIcon } from './icons/note.svg';
import { ReactComponent as UnpaidIcon } from './icons/empty-wallet-remove.svg';
import { ReactComponent as RecallIcon } from './icons/repeat.svg';
import { ReactComponent as InventoryIcon } from './icons/folder.svg';
import { ReactComponent as ScheduledIcon } from './icons/scheduled.svg';
import { FaHourglassEnd } from 'react-icons/fa';
import { ROUTE_KEYS } from '../helpers/constants';
import LiveOrderLayout from '../components/layout/live-orders.layout';
import NewLiveOrderLayout from '../components/layout/new-live-orders.layout';
import ScheduledOrdersLayout from '../components/layout/scheduled-orders-layout';
import PreOrdersLayout from '../components/layout/pre-orders-layout';
import RecallOrdersLayout from '../components/layout/recall-orders-layout';
import DispatchOrdersLayout from '../components/layout/dispatch-orders-layout';
import UnpaidOrdersLayout from '../components/layout/unpaid-orders-layout';
import InventoryLayout from '../components/layout/inventory-layout';

export type ROUTE_TYPE = {
  name: string;
  route: string;
  // TODO :: more TS refactor needed
  component: any;
  Icon: FC<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
};

export const adminRoutes: ROUTE_TYPE[] = [
  {
    name: 'PENDING ORDERS',
    route: ROUTE_KEYS.ADMIN.PENDING,
    component: NewLiveOrderLayout,
    Icon: FaHourglassEnd
  },
  {
    name: 'LIVE ORDERS',
    route: ROUTE_KEYS.ADMIN.HOME,
    component: NewLiveOrderLayout,
    Icon: LiveOrderIcon
  },
  {
    name: 'RECALL',
    route: ROUTE_KEYS.ADMIN.RECALL,
    component: RecallOrdersLayout,
    Icon: RecallIcon
  },
  {
    name: 'DISPATCH',
    route: ROUTE_KEYS.ADMIN.DISPATCH,
    component: DispatchOrdersLayout,
    Icon: DispatchIcon
  },
  {
    name: 'UNPAID',
    route: ROUTE_KEYS.ADMIN.UNPAID,
    component: UnpaidOrdersLayout,
    Icon: UnpaidIcon
  },
  {
    name: 'INVENTORY',
    route: ROUTE_KEYS.ADMIN.INVENTORY,
    component: InventoryLayout,
    Icon: InventoryIcon
  }
];

export const superAdminRoutes: ROUTE_TYPE[] = [
  {
    name: 'LIVE ORDERS',
    route: ROUTE_KEYS.SUPER_ADMIN.HOME,
    component: NewLiveOrderLayout,
    Icon: LiveOrderIcon
  },
  {
    name: 'RECALL',
    route: ROUTE_KEYS.SUPER_ADMIN.RECALL,
    component: RecallOrdersLayout,
    Icon: RecallIcon
  },
  {
    name: 'DISPATCH',
    route: ROUTE_KEYS.SUPER_ADMIN.DISPATCH,
    component: DispatchOrdersLayout,
    Icon: DispatchIcon
  },
  {
    name: 'UNPAID',
    route: ROUTE_KEYS.SUPER_ADMIN.UNPAID,
    component: UnpaidOrdersLayout,
    Icon: UnpaidIcon
  },
  {
    name: 'INVENTORY',
    route: ROUTE_KEYS.SUPER_ADMIN.INVENTORY,
    component: InventoryLayout,
    Icon: InventoryIcon
  }
];

export const kitchenRoutes: ROUTE_TYPE[] = [
  {
    name: 'PENDING ORDERS',
    route: ROUTE_KEYS.KITCHEN.PENDING,
    component: NewLiveOrderLayout,
    Icon: FaHourglassEnd
  },
  {
    name: 'LIVE ORDERS',
    route: ROUTE_KEYS.KITCHEN.HOME,
    component: NewLiveOrderLayout,
    Icon: LiveOrderIcon
  },
  {
    name: 'RECALL',
    route: ROUTE_KEYS.KITCHEN.RECALL,
    component: RecallOrdersLayout,
    Icon: RecallIcon
  },
  {
    name: 'SCHEDULED',
    route: ROUTE_KEYS.KITCHEN.SCHEDULED,
    component: ScheduledOrdersLayout,
    Icon: ScheduledIcon
  },
  {
    name: 'PRE ORDER',
    route: ROUTE_KEYS.KITCHEN.PREORDER,
    component: PreOrdersLayout,
    Icon: RecallIcon
  }
];

export const frontdeskRoutes: ROUTE_TYPE[] = [
  {
    name: 'LIVE ORDERS',
    route: ROUTE_KEYS.FRONT_DESK.HOME,
    component: NewLiveOrderLayout,
    Icon: LiveOrderIcon
  },
  {
    name: 'DISPATCH',
    route: ROUTE_KEYS.FRONT_DESK.DISPATCH,
    component: DispatchOrdersLayout,
    Icon: DispatchIcon
  },
  {
    name: 'UNPAID',
    route: ROUTE_KEYS.FRONT_DESK.UNPAID,
    component: UnpaidOrdersLayout,
    Icon: UnpaidIcon
  }
];

export const packagingRoutes: ROUTE_TYPE[] = [
  {
    name: 'LIVE ORDERS',
    route: ROUTE_KEYS.PACKAGING.HOME,
    component: LiveOrderLayout,
    Icon: LiveOrderIcon
  },
  {
    name: 'RECALL',
    route: ROUTE_KEYS.PACKAGING.RECALL,
    component: RecallOrdersLayout,
    Icon: RecallIcon
  }
];

export const shopRoutes: ROUTE_TYPE[] = [
  {
    name: 'LIVE ORDERS',
    route: ROUTE_KEYS.SHOP.HOME,
    component: LiveOrderLayout,
    Icon: LiveOrderIcon
  },
  {
    name: 'RECALL',
    route: ROUTE_KEYS.SHOP.RECALL,
    component: RecallOrdersLayout,
    Icon: RecallIcon
  },
  {
    name: 'INVENTORY',
    route: ROUTE_KEYS.SHOP.INVENTORY,
    component: InventoryLayout,
    Icon: InventoryIcon
  }
];
