import React, { FC, useEffect, useState } from 'react';
import { withAlert } from 'react-alert';
import styles from './styles.module.css';
import { IInventoryItem } from './types';
import Switch from '../switch';
import homeStore from '../../stores/homeStore';
import TurnOffMeal from '../modals/turnOffMeal';
import moment from 'moment';
import { STORAGE_KEYS } from '../../helpers/constants';

type Props = {
  handleMealUpdate: (meal: IInventoryItem) => void;
  meal: IInventoryItem;
  brandName: string;
  alert: any;
  workingHours: Array<{
    day: string;
    opening_time: string;
  }>;
};

const InventoryItem: FC<Props> = ({
  meal,
  handleMealUpdate,
  brandName,
  alert,
  workingHours
}: Props) => {
  const { updateMeal } = homeStore;
  const [editableMeal, setEditableMeal] = useState({ ...meal });
  const [showModal, setShowModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [active, setIsActive] = useState(false);
  const [timeleft, setTimeleft] = useState('');
  const COKITCHEN_ID = localStorage.getItem(STORAGE_KEYS.COKITCHEN);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment();
      const then = moment(editableMeal.availability_soon_date_);
      if (editableMeal.active) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
      if (editableMeal.availability_soon_date_ != null) {
        const diffInMilliseconds = then.diff(now);
        const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
        const minutes = Math.floor(diffInSeconds / 60);
        const seconds = diffInSeconds % 60;

        setTimeleft(`${minutes}:${seconds} ${minutes === 0 ? 'seconds' : 'minutes'}`);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timeleft, editableMeal.availability_soon_date_]);

  useEffect(() => setEditableMeal({ ...meal }), [meal]);
  const handleSwitch = async (val: boolean, meal: IInventoryItem) => {
    const payload: {
      active: boolean;
      cokitchen_id: string;
      meal_ids?: string[];
      addon_ids?: string[];
      sold_out: boolean;
    } = {
      active: !val && meal.is_addon ? val : true,
      cokitchen_id: COKITCHEN_ID ?? '',
      sold_out: !val
    };
    meal.is_addon ? (payload.addon_ids = [meal.id]) : (payload.meal_ids = [meal.id]);
    if (val) {
      await updateMeal(alert, payload, meal.is_addon);
    } else {
      setShowModal(true);
    }
  };

  const submitData = async (soldOutMinutes: number) => {
    setSubmitting(true);
    const { id: meal_id, is_addon, active } = editableMeal;
    const payload = is_addon
      ? {
          active: false,
          addon_ids: [meal_id],
          cokitchen_id: COKITCHEN_ID,
          sold_out: true
        }
      : {
          active,
          meal_ids: [meal_id],
          availability_soon_date_:
            soldOutMinutes === 0 ? null : moment().add(soldOutMinutes, 'minutes').toDate(),
          ...(active ? { active } : {}),
          cokitchen_id: COKITCHEN_ID,
          sold_out: true
        };
    await updateMeal(alert, payload, is_addon);
    setShowModal(false);
    setSubmitting(false);
  };
  return (
    <div className={styles.main}>
      <div className={styles.title}>
        <div className={editableMeal.active ? '' : styles.titleDisabled}>
          <p className="text-black"> {editableMeal.name}</p>
          {editableMeal.is_addon ? <div className={styles.soldOut}>Addon</div> : <></>}
          {editableMeal.is_combo ? <div className={styles.soldOut}>Combo</div> : <></>}
          {+editableMeal.available_no === 0 && editableMeal.availability_soon_date_ == null && (
            <div className={styles.soldOut}>Sold out</div>
          )}
          {editableMeal.availability_soon_date_ !== null &&
            !moment().isAfter(editableMeal.availability_soon_date_) && (
              <div className={styles.soldOut}>Available in {timeleft}</div>
            )}
        </div>
      </div>
      <div className={styles.actions}>
        <button className={styles.lineButton} onClick={() => handleMealUpdate(meal)}>
          Edit
        </button>
        <Switch active={active} onChange={(e) => handleSwitch(e, meal)} />
      </div>
      <TurnOffMeal
        show={showModal}
        onClose={() => setShowModal(false)}
        name={editableMeal.name}
        brandName={brandName}
        workingHours={workingHours}
        onConfirm={submitData}
        loading={submitting}
      />
    </div>
  );
};

export default withAlert()(InventoryItem);
