/* eslint-disable */

import React, { useState, useEffect } from 'react';
import Layout from '../../components/layout';
import OrderCard from '../../components/orderCard';
import { withRouter } from 'react-router-dom';
import homeStore from '../../stores/homeStore';
import { withAlert } from 'react-alert';
import { FILTER_KEYS, STORAGE_KEYS } from '../../helpers/constants';
import { checkOrderValidityForRole } from './roleMapping.service';
import { FILTERS_BY_ROUTE } from './filters';
import { ENUM_PAGE, ENUM_STATUS } from '../../helpers/enums';
import { getOrderLatestTime } from '../../helpers/functions';
import { observer } from 'mobx-react-lite';
import {
  filterSearchValue,
  getFilteredBrandOrders,
  getLiveLayoutState,
  getPrefilteredFilters
} from './fns.service';
import NoOrder from '../../components/empty-states/noOrder';
import moment from 'moment';
import useLayoutContext from '../../contexts/layout.context';
import { useLocation } from 'react-router';
import Worker from 'worker-loader!../../worker.ts';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';

const LiveOrders = observer(({ alert }) => {
  const { pathname } = useLocation();

  const { testMode, pinnedOrders, setPinnedOrders, deliveryType } = useLayoutContext();
  const [prefilteredOrders, setPrefilteredOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [brandFiltered, setBrandFiltered] = useState([]);
  const [currentFilter, setCurrentFilter] = useState([]);
  const [orderSummaryCount, setOrderSummaryCount] = useState({
    pending: 0,
    tracking: 0
  });
  const [orderSummary, setOrderSummary] = useState({
    pending: [],
    tracking: []
  });
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [scheduledOrders, setScheduledOrders] = useState([]);
  const { activeHeaderBrands, updateActiveHeaderBrands, updateIsBrandSplitScreen } = homeStore;
  const location = useLocation();
  const screenDetails = JSON.parse(localStorage.getItem(STORAGE_KEYS.SCREEN_DETAILS));
  const screen_id = screenDetails?.id;
  const cokitchen_id = localStorage.getItem(STORAGE_KEYS.COKITCHEN);
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);
  const showPendingOrder = pathname.includes('pending');

  const onFilterClick = (filter) => {
    setCurrentFilter(filter);
    const newFilters = filters.reduce((acc, f) => {
      if (f.title === filter.title) f.active = !f.active;
      else f.active = false;
      acc.push(f);
      return acc;
    }, []);
    setFilters(newFilters);
  };

  const initFilters = () => {
    const routeFilters = FILTERS_BY_ROUTE[location.pathname]?.map((filter) => {
      const count = prefilteredOrders.filter((order) => {
        if (filter.key === FILTER_KEYS.PINNED) {
          return pinnedOrders.some((p) => p.id === order.id);
        }
        return filter.condition(order, filter.key);
      }).length;
      return { ...filter, number: count };
    });
    if (routeFilters) {
      const [firstItem, ...others] = routeFilters;
      setFilters([{ ...firstItem, active: true }, ...others]);
      setCurrentFilter(firstItem);
    }
  };

  useEffect(() => {
    const worker = new Worker();
    setLoading(true);
    setOrders([]);
    setOrderSummaryCount({
      pending: 0,
      tracking: 0
    });
    worker.postMessage({
      token: window.localStorage.token,
      role: role,
      page: ENUM_PAGE.LIVE,
      cokitchenId: cokitchen_id,
      screenId: screen_id,
      test: testMode
    });
    worker.onmessage = (message) => {
      if (showPendingOrder) {
        const pendingOrders = message.data.filter((order) => !order.kitchen_accepted);
        setOrders(pendingOrders);
      } else {
        setOrders(message.data);
      }
      setLoading(false);
    };
    return () => worker.terminate();
  }, [testMode, showPendingOrder]);

  const initHeaderBrands = () => {
    const isSplitScreen = JSON.parse(localStorage.getItem(STORAGE_KEYS.IS_BRAND_SPLIT_SCREEN));
    updateIsBrandSplitScreen(!!isSplitScreen);
    if (isSplitScreen) {
      const splitScreen = JSON.parse(localStorage.getItem(STORAGE_KEYS.SCREEN_DETAILS));
      if (splitScreen) {
        const splitScreenBrandIds = splitScreen.brand_ids;
        updateActiveHeaderBrands(splitScreenBrandIds);
      }
    } else {
      let headerBrands = ['ALL'];
      const items = localStorage.getItem(STORAGE_KEYS.HEADER_BRANDS);
      if (items) {
        headerBrands = JSON.parse(items);
      }
      updateActiveHeaderBrands(headerBrands);
    }
  };

  useEffect(async () => {
    initFilters();
    initHeaderBrands();

    // scheduled order alert box
    const timer = setInterval(() => {
      showScheduledAlerts();
    }, 300000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const getMealBrands = () => {
    if (role !== ENUM_KITCHEN_ROLE.KITCHEN_STAFF && role !== ENUM_KITCHEN_ROLE.KITCHEN_ADMIN) {
      const allBrands = filteredOrders.map((order) => ({
        ...order,
        orderKey: order.id
      }));
      setBrandFiltered(
        deliveryType ? allBrands.filter((order) => order.delivery === deliveryType) : allBrands
      );
      return;
    }
    filterMealBrands();
  };

  const filterMealBrands = () => {
    const filtered = getFilteredBrandOrders(filteredOrders, activeHeaderBrands);
    setBrandFiltered(
      deliveryType ? filtered.filter((order) => order.delivery === deliveryType) : filtered
    );
  };

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.PINNED_ORDERS, JSON.stringify(pinnedOrders));
    if (filters.length > 0) {
      const prefiltered = getPrefilteredFilters(filters, prefilteredOrders, pinnedOrders);
      setFilters(prefiltered);
    } else {
      initFilters();
    }
  }, [prefilteredOrders, pinnedOrders]);

  useEffect(() => {
    const activeFilters = filters.filter((filter) => filter.active);
    if (!activeFilters.length) {
      setFilteredOrders([...prefilteredOrders]);
    } else {
      const freshOrders = prefilteredOrders.filter((order) =>
        activeFilters.find((filter) => {
          if (filter.key === FILTER_KEYS.PINNED) {
            return pinnedOrders.some((p) => p.id === order.id);
          }
          return filter.condition(order, filter.key);
        })
      );
      setFilteredOrders(freshOrders);
    }
  }, [prefilteredOrders, filters, pinnedOrders]);

  useEffect(() => {
    setOrderSummary({
      pending: orders.filter((order) => !order.kitchen_accepted),
      tracking: orders.filter((order) => order.kitchen_accepted)
    });

    // scheduled orders
    setScheduledOrders(orders.filter((o) => o.scheduled));
  }, [orders]);

  useEffect(() => {
    setOrderSummaryCount({
      pending: orderSummary.pending.length,
      tracking: orderSummary.tracking.length
    });
  }, [orderSummary]);

  useEffect(() => {
    getMealBrands();
  }, [filteredOrders, deliveryType]);

  useEffect(() => {
    if (role === ENUM_KITCHEN_ROLE.KITCHEN_STAFF || role === ENUM_KITCHEN_ROLE.KITCHEN_ADMIN)
      filterMealBrands();
  }, [activeHeaderBrands]);

  useEffect(() => {
    const search = searchValue?.toUpperCase();
    const filtered = filterSearchValue(orders, search).sort((a, b) => {
      return getOrderLatestTime(b) - getOrderLatestTime(a);
    });
    setPrefilteredOrders(filtered);
  }, [orders, searchValue]);

  useEffect(() => {
    const storedPinnedOrders = JSON?.parse(localStorage.getItem(STORAGE_KEYS.PINNED_ORDERS));
    const filterCompletedOrders = orders?.filter((obj1) =>
      storedPinnedOrders?.some((obj2) => obj2.id === obj1.id)
    );
    if (filterCompletedOrders) {
      setPinnedOrders(filterCompletedOrders);
    }
  }, [orders]);

  const isScheduledOrderLate = (order) => {
    const now = moment();
    const then = moment(order.scheduled_delivery_datetime);
    const diff = then.diff(now, 'minutes');
    return { isLate: diff <= 30, diff };
  };

  const showScheduledAlerts = () => {
    scheduledOrders.forEach((o) => {
      const { isLate, diff } = isScheduledOrderLate(o);
      if (isLate) {
        alert.show(`Schedule order #${o.order_code.toUpperCase()} is due in ${diff} mins`);
      }
    });
  };

  const getLayoutState = () => {
    return getLiveLayoutState(filteredOrders, searchValue, loading, orderSummaryCount);
  };

  return (
    <Layout
      pendingOrders={orderSummaryCount.pending}
      trackingOrders={orderSummaryCount.tracking}
      searchValue={searchValue}
      searchChange={(e) => setSearchValue(e.target.value?.toUpperCase())}
      layoutState={getLayoutState()}
      filters={filters}
      onFilterClick={onFilterClick}>
      {brandFiltered.length === 0 ? (
        <NoOrder />
      ) : (
        brandFiltered.map(({ ...item }, i) => (
          <OrderCard
            key={item.id}
            actionDisabled={item.status === ENUM_STATUS.STARTED}
            order={item}
            currentFilter={currentFilter}
            checkOrderValidityForRole={checkOrderValidityForRole}
          />
        ))
      )}
    </Layout>
  );
});
LiveOrders.displayName = 'LiveOrders';
export default withRouter(withAlert()(LiveOrders));
