import React from 'react';
import ReactToPrint from 'react-to-print';
import { withAlert } from 'react-alert';
import { STORAGE_KEYS } from '../../helpers/constants';
import { ENUM_STATUS } from '../../helpers/enums';
import OrderReady from './order-ready';
import { getButtonText, getTextClass, isButtonDisabled } from './functions';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';

// TODO :: Refactor card button to render different buttons instead of action manipulation
const CardButton = ({
  actionDisabled,
  status,
  paid,
  packagingComplete,
  actionClick,
  actionLoading,
  receiptRef,
  isPickup,
  order = {},
  alert,
  disableReceipt
}) => {
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);
  const disabled = isButtonDisabled({ actionDisabled, packagingComplete, status });
  const canPrintReceipt = [
    ENUM_KITCHEN_ROLE.PACKAGING,
    ENUM_KITCHEN_ROLE.SHOP_STAFF,
    ENUM_KITCHEN_ROLE.KITCHEN_ADMIN,
    ENUM_KITCHEN_ROLE.SUPER_ADMIN,
    ENUM_KITCHEN_ROLE.ADMIN
  ].includes(role);

  return role === ENUM_KITCHEN_ROLE.KITCHEN_STAFF && status === ENUM_STATUS.STARTED ? (
    <OrderReady
      order={order}
      showSuccess={(message) => alert?.show(message, { type: 'success' })}
    />
  ) : (
    <a
      id="cardButton"
      onClick={() => {
        if (!disabled) {
          actionClick?.();
        }
      }}
      className="flex flex-col justify-start items-end">
      {canPrintReceipt && !disableReceipt ? (
        <ReactToPrint
          trigger={() => (
            <button className="rounded-full bg-white px-[29.5px] py-[7px]" disabled={disabled}>
              <span className={getTextClass({ packagingComplete, status, actionDisabled })}>
                {getButtonText({ status, actionDisabled, isPickup, packagingComplete, paid })}
                {actionLoading ? '...' : ''}
              </span>
            </button>
          )}
          content={() => receiptRef.current}
        />
      ) : (
        <button className="rounded-full bg-white px-[29.5px] py-[7px]" disabled={disabled}>
          <span className={getTextClass({ packagingComplete, status, actionDisabled })}>
            {getButtonText({ status, actionDisabled, isPickup, packagingComplete, paid })}
            {actionLoading ? '...' : ''}
          </span>
        </button>
      )}
    </a>
  );
};

export default withAlert()(CardButton);
