import React from 'react';
import { ROUTE_KEYS, STORAGE_KEYS } from '../../helpers/constants';
import { formatPlural } from '../../helpers/functions';
import BrandMeal from './brandMeal';
import { ENUM_ITEM_TYPES } from '../../helpers/enums';
import { observer } from 'mobx-react-lite';
import homeStore from '../../stores/homeStore';
import CardHeaderTag from '../card-header/cardHeaderTag';
import { OrderType } from '../../helpers/types';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';

type BrandType = {
  mainOrder: OrderType;
  status: string;
  onAddonSelect: (_: string) => void;
  onBulkAddonSelect: (_: string[], checkAll: boolean) => void;
  onMealSelect: (_: string) => void;
  selectedMeals: string[];
  selectedAddons: string[];
  order: any;
  isGroupOrder: boolean;
  isLastBrand: boolean;
  subIndex: number;
  isSubOrder: boolean;
  currentFilter?: { key: string };
  brandIsReady: boolean;
  printStickerMeals: string[];
  setPrintStickerMeals: (arg: string) => void;
};

const Brand = observer(
  ({
    mainOrder,
    status,
    selectedMeals,
    selectedAddons,
    order,
    isGroupOrder,
    isLastBrand,
    subIndex,
    isSubOrder,
    currentFilter,
    brandIsReady,
    onAddonSelect,
    onBulkAddonSelect,
    onMealSelect,
    setPrintStickerMeals,
    printStickerMeals
  }: BrandType) => {
    const { activeHeaderBrands } = homeStore;

    const role = localStorage.getItem(STORAGE_KEYS.ROLE) as ENUM_KITCHEN_ROLE;
    const orderCardStyle =
      (order?.brand?.item_type === ENUM_ITEM_TYPES.SHOP &&
        currentFilter?.key === ENUM_ITEM_TYPES.FOOD) ||
      (order?.brand?.item_type === ENUM_ITEM_TYPES.FOOD &&
        currentFilter?.key === ENUM_ITEM_TYPES.SHOP) ||
      (order?.brand?.item_type === ENUM_ITEM_TYPES.SHOP && role === ENUM_KITCHEN_ROLE.PACKAGING)
        ? 'hidden'
        : '';
    /* eslint-disable */
    const brandName: string = order?.brand?.name ?? '';
    const numberOfMeals: number = order?.meals.length ?? 0;

    const selectMeal = (mealIndex: string, mealChecked: boolean, mealId: any) => {
      const meal = order?.meals?.find(({ id }: { id: string }) => id === mealId);
      const addonIndices = meal?.addons?.map((addon: any) => `${mealIndex}-${addon?.id}`) ?? [];
      onBulkAddonSelect(addonIndices, !mealChecked);
      onMealSelect(mealIndex);
    };

    const selectAddon = (addonIndex: string, mealIndex: string, mealId: any) => {
      // check if meal is selected === same as if all addons are selected and deselect the meal
      if (selectedMeals.includes(mealIndex)) {
        onMealSelect(mealIndex);
      } else {
        const meal = order?.meals?.find(({ id }: { id: string }) => id === mealId);
        const addonIndices = meal?.addons?.reduce((acc: string[], addon: any) => {
          const newIndex = `${mealIndex}-${addon?.id}`;
          if (newIndex !== addonIndex) acc.push(newIndex);
          return acc;
        }, []);
        if (addonIndices.every((index: string) => selectedAddons.includes(index))) {
          onMealSelect(mealIndex);
        }
      }
      onAddonSelect(addonIndex);
    };
    /* eslint-enable */
    const showBrandName = () => {
      let returnVal = true;
      if (
        role === ENUM_KITCHEN_ROLE.KITCHEN_STAFF &&
        window.location.pathname === ROUTE_KEYS.KITCHEN.HOME &&
        activeHeaderBrands[0] !== 'ALL' &&
        activeHeaderBrands.length === 1
      ) {
        returnVal = false;
      }
      return returnVal;
    };

    return (
      <div className={`order_card mb-3 w-full ${orderCardStyle}`}>
        <div className={`flex justify-between items-center w-full ${!isGroupOrder ? 'mt-4' : ''}`}>
          <div>
            {showBrandName() && (
              <h6 className="font-normal leading-[22px]">
                {brandName}
                {brandIsReady && role === ENUM_KITCHEN_ROLE.PACKAGING && (
                  <CardHeaderTag type="brandReady" />
                )}
              </h6>
            )}
            <p className="text-[#595959] mb-0 leading-[22px]">
              {formatPlural(numberOfMeals, 'meal')}
            </p>
          </div>
        </div>

        <div className={`w-full mt-4 ${!isLastBrand ? 'border-b border-[#ededed]' : ''}`}>
          {order?.meals?.map((meal: any, index: number) => {
            // eslint-disable-next-line
            const mealIndex = `${order.brand?.id}-${index}-${meal.id}${
              isSubOrder ? `-sub${subIndex}` : ''
            }`;
            const mealChecked = selectedMeals.includes(mealIndex);
            return (
              <BrandMeal
                mainOrder={mainOrder}
                meal={meal}
                mealIndex={mealIndex}
                key={mealIndex}
                isChecked={mealChecked}
                status={status}
                selectedAddons={selectedAddons}
                onMealSelect={() => selectMeal(mealIndex, mealChecked, meal.id)}
                onAddonSelect={(addonIndex) => selectAddon(addonIndex, mealIndex, meal.id)}
                printStickerMeals={printStickerMeals}
                setPrintStickerMeals={setPrintStickerMeals}
              />
            );
          })}
        </div>
      </div>
    );
  }
);

Brand.displayName = 'Brand';

export default Brand;
