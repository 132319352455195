import React, { useState, useEffect } from 'react';
import Layout from '../../components/layout';
import OrderCard from '../../components/orderCard';
import { withRouter } from 'react-router-dom';
import homeStore from '../../stores/homeStore';
import { withAlert } from 'react-alert';
import { SocketIOConnection } from '../../helpers/client_library';
import { FILTER_KEYS, STORAGE_KEYS } from '../../helpers/constants';
import { apiLiveMapping, checkOrderValidityForRole } from './roleMapping.service';
import { FILTERS_BY_ROUTE } from './filters';
import { ENUM_STATUS } from '../../helpers/enums';
import { getOrderLatestTime, showPinnedFilter } from '../../helpers/functions';
import { observer } from 'mobx-react-lite';
import {
  filterSearchValue,
  getFilteredBrandOrders,
  getLiveLayoutState,
  getPrefilteredFilters
} from './fns.service';
import NoOrder from '../../components/empty-states/noOrder';
import moment from 'moment';
import useLayoutContext from '../../contexts/layout.context';
import { useLocation } from 'react-router';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';

const LiveOrders = observer(({ alert }) => {
  const {
    activePinFilter,
    pinnedOrders,
    testMode,
    setPinnedOrders,
    setActivePinFilter,
    deliveryType
  } = useLayoutContext();
  const [prefilteredOrders, setPrefilteredOrders] = useState([]);
  const [downloadingInitData, setDdownloadingInitData] = useState(true);
  const [ordersDict, setOrdersDict] = useState({});
  const [orders, setOrders] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [brandFiltered, setBrandFiltered] = useState([]);
  const [currentFilter, setCurrentFilter] = useState([]);
  const [orderSummaryCount, setOrderSummaryCount] = useState({
    pending: 0,
    tracking: 0
  });
  const [orderSummary, setOrderSummary] = useState({
    pending: [],
    tracking: []
  });
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [scheduledOrders, setScheduledOrders] = useState([]);
  const {
    activeHeaderBrands,
    updateActiveHeaderBrands,
    updateIsBrandSplitScreen,
    updateIsBrandPackagingScreen
  } = homeStore;
  const location = useLocation();
  const screenDetails = JSON.parse(localStorage.getItem(STORAGE_KEYS.SCREEN_DETAILS));
  const packagingScreenDetails = JSON.parse(
    localStorage.getItem(STORAGE_KEYS.PACKAGING_SCREEN_DETAILS)
  );
  const screen_id = screenDetails?.id;
  const packaging_screen_id = packagingScreenDetails?.id;
  const cokitchen_id = localStorage.getItem(STORAGE_KEYS.COKITCHEN);
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);

  const onFilterClick = (filter) => {
    setCurrentFilter(filter);
    const newFilters = filters.reduce((acc, f) => {
      if (f.title === filter.title) f.active = !f.active;
      else f.active = false;
      acc.push(f);
      return acc;
    }, []);
    setFilters(newFilters);
  };
  const initFilters = () => {
    const routeFilters = FILTERS_BY_ROUTE[location.pathname]?.map((filter) => {
      const count = prefilteredOrders.filter((order) => filter.condition(order, filter.key)).length;
      return { ...filter, number: count };
    });
    if (routeFilters) {
      const [firstItem, ...others] = routeFilters;
      setFilters([{ ...firstItem, active: true }, ...others]);
      setCurrentFilter(firstItem);
    }
  };

  const getRoomName = () => {
    const isSplitScreen = JSON.parse(localStorage.getItem(STORAGE_KEYS.IS_BRAND_SPLIT_SCREEN));
    const isPackagingScreen = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.IS_BRAND_PACKAGING_SCREEN)
    );
    if (screen_id && isSplitScreen) {
      return `order-update-screen:${screen_id}`;
    }
    if (packaging_screen_id && isPackagingScreen) {
      return `order-update-screen:${packaging_screen_id}`;
    }
    if (cokitchen_id) {
      return `order-update-cokitchen:${cokitchen_id}`;
    }
    return 'order-update';
  };

  const initPage = async (connection) => {
    const roomName = getRoomName();
    connection.joinRoom(roomName, process.env.REACT_APP_WEBOCKETAPP_KEY);
    await loadOrders();
    setLoading(false);
  };

  const initHeaderBrands = () => {
    const isSplitScreen = JSON.parse(localStorage.getItem(STORAGE_KEYS.IS_BRAND_SPLIT_SCREEN));
    const isPackagingScreen = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.IS_BRAND_PACKAGING_SCREEN)
    );
    updateIsBrandSplitScreen(!!isSplitScreen);
    updateIsBrandPackagingScreen(!!isPackagingScreen);
    if (isSplitScreen) {
      const splitScreen = JSON.parse(localStorage.getItem(STORAGE_KEYS.SCREEN_DETAILS));
      if (splitScreen) {
        const splitScreenBrandIds = splitScreen.brand_ids;
        updateActiveHeaderBrands(splitScreenBrandIds);
      }
    } else {
      let headerBrands = ['ALL'];
      const items = localStorage.getItem(STORAGE_KEYS.HEADER_BRANDS);
      if (items) {
        headerBrands = JSON.parse(items);
      }
      updateActiveHeaderBrands(headerBrands);
    }
  };

  useEffect(async () => {
    initFilters();
    setLoading(true);
    const connection = await SocketIOConnection.getConnection();
    connection.onReconnect(() => initPage(connection));
    connection.addEventListener(getRoomName(), 'updated', (order) => {
      handleUpdatedOrder(order);
    });
    connection.addEventListener(getRoomName(), 'removed', (order) => {
      removeOrderIfExist(order);
    });
    initPage(connection);
    initHeaderBrands();

    // scheduled order alert box
    const timer = setInterval(() => {
      showScheduledAlerts();
    }, 300000);
    return () => {
      clearInterval(timer);
      connection.removeListener(getRoomName(), 'updated');
      connection.removeListener(getRoomName(), 'removed');
    };
  }, [testMode]);

  const loadOrders = async () => {
    const fn = apiLiveMapping[role];
    setOrders([]);
    setOrdersDict({});
    setOrderSummaryCount({ pending: 0, tracking: 0 });
    const res = await fn({
      kitchen_split_screen_id: screen_id,
      packaging_split_screen_id: packaging_screen_id,
      cokitchen_id,
      test: testMode
    });
    setDdownloadingInitData(true);
    const last_update_time = res.data.reduce((last_time, order) => {
      const updated_time = new Date(order.updated_at);
      return updated_time > last_time ? updated_time : last_time;
    }, new Date(0));
    clearPreviousOrderData(last_update_time);
    res.data.forEach((order) => handleUpdatedOrder(order));
    setDdownloadingInitData(false);
  };

  const togglePinnedOrders = async () => {
    if (activePinFilter?.title === FILTER_KEYS.PINNED) {
      setOrders(pinnedOrders);
    } else {
      if (downloadingInitData) return;
      const newOrders = Object.values(ordersDict).filter((order) => !order.del);
      await setOrders(newOrders.sort((a, b) => getOrderLatestTime(b) - getOrderLatestTime(a)));
    }
  };

  const getMealBrands = () => {
    if (role !== ENUM_KITCHEN_ROLE.KITCHEN_STAFF && role !== ENUM_KITCHEN_ROLE.KITCHEN_ADMIN) {
      const allBrands = filteredOrders.map((order) => ({
        ...order,
        orderKey: order.id
      }));
      setBrandFiltered(
        deliveryType ? allBrands.filter((order) => order.delivery === deliveryType) : allBrands
      );
      return;
    }
    filterMealBrands();
  };

  const filterMealBrands = () => {
    const filtered = getFilteredBrandOrders(filteredOrders, activeHeaderBrands);
    setBrandFiltered(
      deliveryType ? filtered.filter((order) => order.delivery === deliveryType) : filtered
    );
  };

  useEffect(() => {
    if (filters.length > 0) {
      const prefiltered = getPrefilteredFilters(filters, prefilteredOrders);
      setFilters(prefiltered);
    } else {
      initFilters();
    }
  }, [prefilteredOrders]);

  useEffect(() => {
    const activeFilters = filters.filter((filter) => filter.active);
    if (!activeFilters.length) {
      setFilteredOrders([...prefilteredOrders]);
    } else {
      setFilteredOrders(
        prefilteredOrders.filter((order) =>
          activeFilters.find((filter) => filter.condition(order, filter.key))
        )
      );
    }
  }, [prefilteredOrders, filters]);

  useEffect(() => {
    setOrderSummary({
      pending: orders.filter((order) => !order.kitchen_accepted),
      tracking: orders.filter((order) => order.kitchen_accepted)
    });

    // scheduled orders
    setScheduledOrders(orders.filter((o) => o.scheduled));
  }, [orders]);

  useEffect(() => {
    setOrderSummaryCount({
      pending: orderSummary.pending.length,
      tracking: orderSummary.tracking.length
    });
  }, [orderSummary]);

  useEffect(() => {
    getMealBrands();
  }, [filteredOrders, deliveryType]);

  useEffect(() => {
    if (role === ENUM_KITCHEN_ROLE.KITCHEN_STAFF || role === ENUM_KITCHEN_ROLE.KITCHEN_ADMIN) filterMealBrands();
  }, [activeHeaderBrands]);

  useEffect(() => {
    const search = searchValue?.toUpperCase();
    const filtered = filterSearchValue(orders, search).sort((a, b) => {
      return getOrderLatestTime(b) - getOrderLatestTime(a);
    });
    setPrefilteredOrders(filtered);
  }, [orders, searchValue]);

  useEffect(() => {
    togglePinnedOrders();
  }, [activePinFilter, ordersDict, downloadingInitData]);

  useEffect(() => {
    if (showPinnedFilter(location.pathname)) {
      const storedPinnedOrders = JSON?.parse(localStorage.getItem(STORAGE_KEYS.PINNED_ORDERS));
      const filterCompletedOrders = orders?.filter((obj1) =>
        storedPinnedOrders?.some((obj2) => obj2.id === obj1.id)
      );
      if (filterCompletedOrders) {
        setPinnedOrders(filterCompletedOrders);
      }
    }
  }, [orders]);

  const isScheduledOrderLate = (order) => {
    const now = moment();
    const then = moment(order.scheduled_delivery_datetime);
    const diff = then.diff(now, 'minutes');
    return { isLate: diff <= 30, diff };
  };

  const showScheduledAlerts = () => {
    scheduledOrders.forEach((o) => {
      const { isLate, diff } = isScheduledOrderLate(o);
      if (isLate) {
        alert.show(`Schedule order #${o.order_code.toUpperCase()} is due in ${diff} mins`);
      }
    });
  };
  const updateOrdersDataDict = (order) => {
    setOrdersDict((prevState) => {
      const prevOrder = prevState[order.id];
      const prevOrderTime = prevOrder ? new Date(prevOrder.updated_at) : new Date(0);
      const newOrderDataTime = new Date(order.updated_at);
      return { ...prevState, [order.id]: newOrderDataTime >= prevOrderTime ? order : prevOrder };
    });
  };

  const FILTER_LIST = [
    {
      title: FILTER_KEYS.ALL,
      active: activePinFilter?.title === FILTER_KEYS.ALL,
      key: '0'
    },
    {
      title: FILTER_KEYS.PINNED,
      active: activePinFilter?.title === FILTER_KEYS.PINNED,
      key: '1'
    }
  ];

  const handlePinnedFilterClick = (filter) => {
    setActivePinFilter(filter);
  };

  useEffect(() => {
    setActivePinFilter(FILTER_LIST[0]);
  }, []);

  const clearPreviousOrderData = (time) => {
    // this function clears all orders before the max updated_time of newly downloaded order data
    setOrdersDict((prevState) => {
      return Object.values(prevState)
        .filter((order) => new Date(order.updated_at) > time)
        .reduce((dict, order) => {
          dict[order.id] = order;
          return dict;
        }, {});
    });
  };

  const handleUpdatedOrder = (order) => {
    const isValidOrder = checkOrderValidityForRole(
      order,
      screen_id,
      cokitchen_id,
      testMode,
      packaging_screen_id
    );
    if (isValidOrder) {
      updateOrdersDataDict(order);
      const scheduleExist = scheduledOrders.find((o) => o.id === order.id);
      if (order.scheduled && !scheduleExist) {
        alert.show(
          `You've got an order scheduled for ${moment(order.scheduled_delivery_datetime).format(
            'hh:mm A'
          )}`
        );
      }
    } else {
      removeOrderIfExist(order);
    }
  };

  const removeOrderIfExist = (order) => {
    updateOrdersDataDict({ id: order.id, del: true, updated_at: new Date() });
  };

  const getLayoutState = () => {
    return getLiveLayoutState(filteredOrders, searchValue, loading, orderSummaryCount);
  };

  return (
    <Layout
      handlePinnedFilterClick={handlePinnedFilterClick}
      filterList={FILTER_LIST}
      pendingOrders={orderSummaryCount.pending}
      trackingOrders={orderSummaryCount.tracking}
      searchValue={searchValue}
      searchChange={(e) => setSearchValue(e.target.value?.toUpperCase())}
      layoutState={getLayoutState()}
      filters={filters}
      onFilterClick={onFilterClick}>
      {brandFiltered.length === 0 ? (
        <NoOrder />
      ) : (
        brandFiltered.map(({ ...item }, i) => (
          <OrderCard
            key={item.id}
            actionDisabled={item.status === ENUM_STATUS.STARTED}
            order={item}
            currentFilter={currentFilter}
            checkOrderValidityForRole={checkOrderValidityForRole}
          />
        ))
      )}
    </Layout>
  );
});
LiveOrders.displayName = 'LiveOrders';
export default withRouter(withAlert()(LiveOrders));
