import React, { useState, useEffect } from 'react';
import Layout from '../../components/layout';
import OrderCard from '../../components/orderCard';
import { useLocation, withRouter } from 'react-router-dom';
import { withAlert } from 'react-alert';
import { SocketIOConnection } from '../../helpers/client_library';
import { STORAGE_KEYS } from '../../helpers/constants';
import { apiLiveMapping, checkOrderValidityForRole } from './roleMapping.service';
import { FILTERS_BY_ROUTE } from '../live/filters';
import { ENUM_LAYOUT_STATE, ENUM_STATUS } from '../../helpers/enums';
import { getOrderLatestTime } from '../../helpers/functions';
import useLayoutContext from '../../contexts/layout.context';

const Recall = ({ alert }) => {
  const { testMode, deliveryType } = useLayoutContext();
  const [removedOrder, setRemovedOrder] = useState(null);
  const [updatedOrder, setUpdatedOrder] = useState(null);
  const [prefilteredOrders, setPrefilteredOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [currentFilter, setCurrentFilter] = useState([]);

  const [orderSummary, setOrderSummary] = useState({
    pendingOrdersCount: 0,
    trackingOrdersCount: 0
  });
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const cokitchenId = localStorage.getItem(STORAGE_KEYS.COKITCHEN);
  const location = useLocation();
  const onFilterClick = (filter) => {
    setCurrentFilter(filter);
    const newFilters = filters.reduce((acc, f) => {
      if (f.title === filter.title) f.active = !f.active;
      else f.active = false;
      acc.push(f);
      return acc;
    }, []);
    setFilters(newFilters);
  };
  const initFilters = () => {
    const routeFilters = FILTERS_BY_ROUTE[location.pathname]?.map((filter) => {
      const count = prefilteredOrders.filter((order) => filter.condition(order, filter.key)).length;
      return { ...filter, number: count };
    });
    if (routeFilters) {
      const [firstItem, ...others] = routeFilters;
      setFilters([{ ...firstItem, active: true }, ...others]);
      setCurrentFilter(firstItem);
    }
  };
  useEffect(() => {
    initFilters();
  }, []);

  useEffect(() => {
    if (filters.length > 0) {
      setFilters(
        filters.map((filter) => {
          const count = prefilteredOrders.filter((order) =>
            filter.condition(order, filter.key)
          ).length;
          return { ...filter, number: count };
        })
      );
    } else {
      initFilters();
    }
  }, [prefilteredOrders]);

  useEffect(() => {
    const activeFilters = filters.filter((filter) => filter.active);
    if (!activeFilters.length) {
      setFilteredOrders([...prefilteredOrders]);
    } else {
      setFilteredOrders(
        prefilteredOrders.filter((order) =>
          activeFilters.find((filter) => filter.condition(order, filter.key))
        )
      );
    }
  }, [prefilteredOrders, filters]);

  const loadOrders = async () => {
    const role = localStorage.getItem(STORAGE_KEYS.ROLE);
    const fn = apiLiveMapping[role];
    const res = await fn({ cokitchen_id: cokitchenId, test: testMode });
    setOrders(res?.data?.filter((d) => d?.calculated_order?.cokitchen_id === cokitchenId));
  };

  const initPage = async (connection) => {
    await loadOrders();
    await connection.joinRoom(getRoomName(), process.env.REACT_APP_WEBOCKETAPP_KEY);
    setLoading(false);
  };

  const getRoomName = () => {
    if (cokitchenId) {
      return `order-update-cokitchen:${cokitchenId}`;
    }
    return 'order-update';
  };

  useEffect(async () => {
    setLoading(true);
    setOrders([]);
    setOrderSummary({
      pendingOrdersCount: 0,
      trackingOrdersCount: 0
    });
    const connection = await SocketIOConnection.getConnection();
    connection.onReconnect(() => initPage(connection));
    connection.addEventListener(getRoomName(), 'updated', (order) => {
      setUpdatedOrder(order);
    });
    connection.addEventListener(getRoomName(), 'removed', (order) => {
      setRemovedOrder(order);
    });
    initPage(connection);
    return () => {
      connection.removeListener(getRoomName(), 'updated');
      connection.removeListener(getRoomName(), 'removed');
    };
  }, [testMode]);

  useEffect(() => {
    setOrderSummary({
      pendingOrdersCount: orders.filter((order) => !order.kitchen_accepted).length,
      trackingOrdersCount: orders.filter((order) => order.kitchen_accepted).length
    });
  }, [orders]);

  useEffect(() => {
    if (!removedOrder) return;
    removeOrderIfExist(removedOrder);
  }, [removedOrder]);

  useEffect(() => {
    const search = searchValue?.toUpperCase();
    const filtered = orders.filter((order) => {
      const searchFilter =
        order?.order_code?.toUpperCase().includes(search) ||
        (order?.calculated_order?.user?.first_name + ' ' + order?.calculated_order?.user?.last_name)
          ?.toUpperCase()
          .includes(search) ||
        order?.calculated_order?.user?.phone_number?.toUpperCase().includes(search) ||
        order?.calculated_order?.user?.email?.toUpperCase().includes(search);

      const groupOrderFilter = order.calculated_order?.group_sub_order;
      return searchFilter && !groupOrderFilter;
    });
    setPrefilteredOrders(
      filtered
        .filter((order) => {
          if (deliveryType) {
            return deliveryType === order.delivery;
          } else {
            return order;
          }
        })
        ?.sort((a, b) => {
          return getOrderLatestTime(b) - getOrderLatestTime(a);
        })
    );
  }, [orders, searchValue, deliveryType]);

  const handleUpdatedOrder = () => {
    const order = updatedOrder;
    if (!updatedOrder) return;
    const isValidOrder = checkOrderValidityForRole(order, cokitchenId, testMode);
    if (isValidOrder) {
      let updatedAllOrders = orders.filter((item) => order.id !== item.id);
      updatedAllOrders.push(updatedOrder);
      updatedAllOrders = updatedAllOrders.sort(
        (a, b) => getOrderLatestTime(b) - getOrderLatestTime(a)
      );
      setOrders(updatedAllOrders);
    } else {
      removeOrderIfExist(order);
    }
  };

  useEffect(handleUpdatedOrder, [updatedOrder]);

  const removeOrderIfExist = (order) => {
    const updatedAllOrders = orders.filter((item) => item.id !== order.id);
    if (updatedAllOrders.length !== orders.length) setOrders(updatedAllOrders);
    setOrders(updatedAllOrders);
  };

  const getLayoutState = () => {
    if (filteredOrders.length === 0 && searchValue) return ENUM_LAYOUT_STATE.EMPTY_SEARCH;
    if (
      ((orderSummary?.trackingOrdersCount === 0 && orderSummary?.pendingOrdersCount === 0) ||
        filteredOrders?.length === 0) &&
      !loading
    ) {
      return ENUM_LAYOUT_STATE.WAITING;
    }
    if (orders.length === 0 && loading) return ENUM_LAYOUT_STATE.LOADING;
    return ENUM_LAYOUT_STATE.PAGE;
  };

  return (
    <Layout
      pendingOrders={orderSummary.pendingOrdersCount}
      trackingOrders={orderSummary.trackingOrdersCount}
      searchValue={searchValue}
      searchChange={(e) => setSearchValue(e.target.value?.toUpperCase())}
      layoutState={getLayoutState()}
      filters={filters}
      onFilterClick={onFilterClick}>
      {filteredOrders.map((item, i) => (
        <OrderCard
          key={item.id + item.updated_at}
          actionDisabled={item.status === ENUM_STATUS.STARTED}
          order={item}
          currentFilter={currentFilter}
          checkOrderValidityForRole={checkOrderValidityForRole}
        />
      ))}
    </Layout>
  );
};
export default withRouter(withAlert()(Recall));
