import React from 'react';
import { BaseModal, Button } from '@cokitchen/cokitchen-components';
import dataService from '../../../services/data.service';
import { ReactComponent as DeleteEmoji } from '../../../assets/icons/delete-emoji.svg';

type FormDataType = {
  id?: string;
  name: string;
  active: boolean;
  brand_ids: string[];
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onDelete?: () => void;
  data: FormDataType;
};
const DeleteScreenModal = ({ isOpen, onClose, onDelete, data }: Props) => {
  const handleDelete = async () => {
    try {
      await dataService.deleteAuthData(`kitchen/screens/${data?.id ?? ''}`);
      onDelete?.();
      onClose();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <BaseModal isOpen={isOpen} onRequestClose={onClose} title="">
      <div className="flex flex-col justify-center items-center mb-[12px]">
        <DeleteEmoji />
        <p className="font-medium text-[18px] mb-3">Delete {data.name}?</p>

        <p className="text-sm text-[#6F7376] text-center mb-4">
          Are you sure you want to delete this screen
        </p>
      </div>
      <div className="w-[103%] ml-[-1rem] pl-2 pt-[24px] flex justify-between">
        <Button variant="outline" color="primary" className="mr-4 h-[49px]" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="block" color="primary" className="h-[49px]" onClick={handleDelete}>
          Delete
        </Button>
      </div>
    </BaseModal>
  );
};

export default DeleteScreenModal;
