import React, { useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { Dropdown, DropdownItem, DropdownList } from '@cokitchen/cokitchen-components';

const DeliveryType = ({ deliveryType, setDeliveryType }) => {
  const DELIVERY_TYPES = ['foodcourt', 'chowdeck'];
  const [deliveryTypeDropdown, setDeliveryTypeDropdown] = useState(false);

  const toggleDropdown = () => {
    setDeliveryTypeDropdown(!deliveryTypeDropdown);
  };
  const changeDeliveryType = (type) => {
    setDeliveryType(type);
    toggleDropdown();
  };
  return (
    <Dropdown
      visible={deliveryTypeDropdown}
      onClickOutside={() => setDeliveryTypeDropdown(false)}
      content={
        <div className="flex items-center gap-2 capitalize" role="button" onClick={toggleDropdown}>
          <div>{deliveryType || 'Delivery Type'}</div>
          <BiChevronDown
            size={20}
            className="rounded-full text-gray-5 cursor-pointer hover:bg-gray-1 transition-all"
          />
        </div>
      }>
      <DropdownList>
        <DropdownItem
          className="text-sm font-medium text-[#2e3031]"
          onClick={() => changeDeliveryType('')}>
          All
        </DropdownItem>
        {DELIVERY_TYPES.map((type) => (
          <DropdownItem
            key={type}
            className="text-sm font-medium text-[#2e3031]"
            onClick={() => changeDeliveryType(type)}>
            {type}
          </DropdownItem>
        ))}
      </DropdownList>
    </Dropdown>
  );
};

export default DeliveryType;
