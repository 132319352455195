import React from 'react';
import { ReactComponent as NoScreenIcon } from '../../../assets/icons/no_screen.svg';
import { Button } from '@cokitchen/cokitchen-components';
import { STORAGE_KEYS } from '../../../helpers/constants';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';

type Props = {
  setShow: (show: boolean) => void;
  onClose: () => void;
};
const NoScreens = ({ setShow }: Props) => {
  const role = localStorage.getItem(STORAGE_KEYS.ROLE) as ENUM_KITCHEN_ROLE;
  return (
    <div>
      <div className="w-full h-[30vh] flex flex-col gap-2 justify-center items-center">
        <NoScreenIcon />
        <p className="text-[#1A1A1A] font-medium text-sm">
          {role.includes('ADMIN') ? 'You have not created any screen' : 'No screens available'}
        </p>
      </div>
      {role.includes('ADMIN') && (
        <div className="border-t border-t-[#EBEBEB] w-[109%] ml-[-1.5rem] px-6 pt-[24px] flex items-center justify-center">
          <Button
            variant="block"
            color="primary"
            onClick={() => {
              setShow(true);
            }}>
            Create Screen
          </Button>
        </div>
      )}
    </div>
  );
};

export default NoScreens;
