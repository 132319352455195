import React, { FC, useState } from 'react';
import './styles.scss';
import { BaseModal, Button, Input, Textarea } from '@cokitchen/cokitchen-components';
import { ReactComponent as TrashIcon } from '../../assets/images/trash-icon.svg';
import { REJECTION_REASONS } from '../../helpers/constants';

interface ConfirmRejectModalProps {
  show: boolean;
  loading: boolean;
  onClose: () => void;
  onConfirm: (arg0: { value: string; reason?: string }) => void;
}

interface ConfirmRejectFooterProps {
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmRejectFooter: FC<ConfirmRejectFooterProps> = ({ onCancel, onConfirm, loading }) => (
  <div className="grid grid-cols-2 gap-x-4">
    <Button small color="primary" variant="outline" className="mx-auto" onClick={onCancel}>
      Cancel
    </Button>
    <Button
      small
      color="primary"
      variant="block"
      className="mx-auto"
      loading={loading}
      onClick={onConfirm}>
      Confirm
    </Button>
  </div>
);

const ConfirmRejectModal: FC<ConfirmRejectModalProps> = ({ show, loading, onClose, onConfirm }) => {
  const [value, setValue] = useState<string>('');
  const [reason, setReason] = useState<string>('');
  const OTHER_VALUE = REJECTION_REASONS[REJECTION_REASONS.length - 1];

  const sendValues = (): void => {
    if (value === OTHER_VALUE) {
      onConfirm({ value, reason });
      return;
    }
    onConfirm({ value });
  };

  return (
    <BaseModal
      isOpen={show}
      title="Confirm Rejection*"
      description="Any reason why you are rejecting this order?"
      titleIcon={<TrashIcon />}
      titleIconColor="danger"
      onRequestClose={onClose}
      footer={<ConfirmRejectFooter loading={loading} onCancel={onClose} onConfirm={sendValues} />}>
      <div className="grid grid-cols-2 gap-x-4 gap-y-3 text-font-color mb-[24px]" role="radiogroup">
        {REJECTION_REASONS.map((reason, i) => (
          <Input
            key={i}
            label={reason}
            value={reason}
            type="radio"
            onChange={() => setValue(reason)}
            name="rejection-reasons"
          />
        ))}
      </div>
      {value === OTHER_VALUE && (
        <Textarea
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder="Add a comment..."
        />
      )}
    </BaseModal>
  );
};

export default ConfirmRejectModal;
