import React, { useEffect, useRef, useState } from 'react';
import Surge from './surgeOrder';
import { observer } from 'mobx-react-lite';
import homeStore from '../../stores/homeStore';
import './styles.scss';
import { withAlert } from 'react-alert';
import { useSpeechSynthesis } from 'react-speech-kit';
import CardLayout from '../card-layout';
import CardBody from '../card-body';
import CardFooter from '../card-footer';
import ConfirmRejectModal from '../modals/confirmRejectModal';
import { SocketIOConnection } from '../../helpers/client_library';
import { STORAGE_KEYS } from '../../helpers/constants';
import { ENUM_ITEM_TYPES, ENUM_STATUS } from '../../helpers/enums';
import CardHeader from '../card-header';
import { LiveStatusConfig } from '../../helpers/role-config/live-orders';
import OrderReceipt from '../order-receipt';
import { getCustomButtonConfig } from './custom-button-config';
import UserInformationModal from '../modals/userInformationModal';
import { filterMealsByItemType } from '../../helpers/functions';
import BoxNumberSection from './box-number-section';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';
import useLayoutContext from '../../contexts/layout.context';
import { toast } from 'react-toastify';

const OrderCard = observer(function OrderCard({
  order: pOrder,
  actionDisabled,
  onActionClick,
  actionClick,
  reason,
  riderAssigned,
  alert,
  defaultStatus,
  currentFilter,
  checkOrderValidityForRole,
  hideCardButton,
  isPreOrderPage
}) {
  const content = useRef(null);
  const { setReceiptToPrint } = useLayoutContext();
  const [actionLoading, setActionLoading] = useState(false);
  const { speak } = useSpeechSynthesis();
  const [setActive, setActiveState] = useState('activeAccordion');
  const [setHeight, setHeightState] = useState('');
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [setRotate, setRotateState] = useState('accordion__icon rotate');
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [hasPlayedSound, setHasPlayedSound] = useState(false);
  const [show, setShow] = useState(true);
  const [boxNumber, setBoxNumber] = useState('');

  const [rejectLoading, setRejectLoading] = useState(false);
  const [viewDetails, setViewDetails] = useState(true);

  const [status, setStatus] = useState('');
  const [orderState, setOrderState] = useState(pOrder);
  const [order, setOrder] = useState({});
  const [selectedMeals, setSelectedMeals] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [cutleryChecked, setCutleryChecked] = useState(false);
  const [packagingComplete, setPackagingComplete] = useState(false);
  const [finishOrder, setFinishOrder] = useState(false);
  const [subOrderCount, setSubOrderCount] = useState(0);
  const [itemType, setItemType] = useState(ENUM_ITEM_TYPES.FOOD);
  const [disableReceipt, setDisableReceipt] = useState(false);

  // old props turned state
  const [time, setTime] = useState('');
  const [calculatedOrder, setCalculatedOrder] = useState('');
  const [orderType, setOrderType] = useState('');
  const [orderDetails, setOrderDetails] = useState('');
  const [paid, setPaid] = useState(false);
  const [printStickerMeals, setPrintStickerMeals] = useState([]);

  const role = localStorage.getItem(STORAGE_KEYS.ROLE);
  const canManageSurge = role !== ENUM_KITCHEN_ROLE.FRONT_DESK;

  useEffect(() => {
    let itemType = '';
    if ([ENUM_KITCHEN_ROLE.KITCHEN_STAFF, ENUM_KITCHEN_ROLE.PACKAGING].includes(role)) {
      itemType = ENUM_ITEM_TYPES.FOOD;
    } else if (role === ENUM_KITCHEN_ROLE.SHOP_STAFF) {
      itemType = ENUM_ITEM_TYPES.SHOP;
    } else {
      itemType =
        currentFilter?.key === ENUM_ITEM_TYPES.SHOP ? ENUM_ITEM_TYPES.SHOP : ENUM_ITEM_TYPES.FOOD;
    }
    setItemType(itemType);
  }, [currentFilter]);

  const showAllItemTypes = [
    ENUM_KITCHEN_ROLE.FRONT_DESK,
    ENUM_KITCHEN_ROLE.KITCHEN_ADMIN,
    ENUM_KITCHEN_ROLE.SUPER_ADMIN
  ].includes(role);

  const filterItemType =
    role === ENUM_KITCHEN_ROLE.SHOP_STAFF ? ENUM_ITEM_TYPES.SHOP : ENUM_ITEM_TYPES.FOOD;

  const orders = showAllItemTypes
    ? order?.calculated_order?.meals
    : filterMealsByItemType(order?.calculated_order?.meals ?? [], filterItemType);

  const isGroupOrder = !!calculatedOrder?.is_group_order;

  // if order is a group order
  const subOrders = calculatedOrder?.sub_calculated_order_ids?.length
    ? calculatedOrder?.sub_calculated_order_ids.reduce((acc, { meals, ...subOrder }) => {
        const filtered = filterMealsByItemType(meals, filterItemType);
        if (filtered.length > 0) acc.push({ meals: filtered, ...subOrder });
        return acc;
      }, [])
    : [];

  useEffect(async () => {
    setTime(pOrder?.created_at || pOrder?.calculated_order?.created_at);
    setCalculatedOrder(pOrder?.calculated_order);
    setOrderType(pOrder?.order_type);
    setPaid(pOrder?.paid);
    const pOrderDetails = pOrder?.order_details;
    const orderDetails = pOrderDetails === 'string' ? JSON.parse(pOrderDetails) : pOrderDetails;
    setOrderDetails(orderDetails);
    // socket connection
    const conn = await SocketIOConnection.getConnection();
    const roomName = `order:${pOrder.id}`;
    conn.addEventListener(roomName, 'updated', (data) => {
      const updatedOrder = { ...orderState, ...data };
      const fn = LiveStatusConfig[role];
      const status = defaultStatus ?? fn(updatedOrder, itemType);
      if (checkOrderValidityForRole && !checkOrderValidityForRole(updatedOrder)) {
        setShow(false);
      }
      setStatus(status);
      setOrderState({ ...orderState, ...data });
    });
    conn.joinRoom(roomName, process.env.REACT_APP_WEBOCKETAPP_KEY);
    return () => conn.removeListener(roomName, 'updated');
  }, []);
  let numberOfBrandAndMeal = null;
  if (role === ENUM_KITCHEN_ROLE.KITCHEN_STAFF && calculatedOrder.originalMeals) {
    numberOfBrandAndMeal = calculatedOrder.originalMeals.filter((item) => {
      return item.brand.item_type === ENUM_ITEM_TYPES.FOOD;
    });
  }
  useEffect(() => {
    // add other role functions in service file
    const fn = LiveStatusConfig[role];
    const status = defaultStatus ?? fn(orderState, itemType);
    let subCountTotal = 0;
    orderState?.calculated_order?.sub_calculated_order_ids?.forEach((item) => {
      const totalSubCount = item?.meals?.reduce((acc, item) => {
        const mealCount = item.meals?.filter((meal) => meal.item_type === itemType)?.length ?? 0;
        return acc + mealCount;
      }, 0);
      subCountTotal += totalSubCount;
    });
    setSubOrderCount(subCountTotal);
    setOrder({ ...orderState, status });
    setStatus(status);
  }, [orderState, itemType]);

  const customActionFn = async () => {
    setActionLoading(true);
    try {
      onActionClick?.();
      if (
        role === ENUM_KITCHEN_ROLE.SHOP_STAFF &&
        status === ENUM_STATUS.STARTED &&
        !packagingComplete
      ) {
        return;
      }
      const configFn = getCustomButtonConfig();
      if (configFn) {
        const fn = configFn({ ...order, status }, itemType);
        const isPickupComplete =
          role === ENUM_KITCHEN_ROLE.FRONT_DESK &&
          order.calculated_order.pickup &&
          order.paid &&
          status === ENUM_STATUS.DISPATCH;
        const payload = isPickupComplete ? order.id : order.order_code;
        const res = await fn?.(payload);
        setBoxNumber(res?.order?.box_number);
        const updatedOrder = { ...orderState, ...res.order };
        if (checkOrderValidityForRole && !checkOrderValidityForRole(updatedOrder)) {
          setShow(false);
        }
        setOrderState(updatedOrder);
      } else {
        actionClick(order.order_code);
      }
    } catch (err) {}
    setActionLoading(false);
  };
  useEffect(() => {
    if (boxNumber && role === ENUM_KITCHEN_ROLE.PACKAGING) {
      showBoxAllocationAlert();
      setDisableReceipt(false);
      setReceiptToPrint({ shouldPrint: true, order: getReceiptData() });
    }
  }, [boxNumber]);

  const orderItems = orders?.filter((item) => item.brand.item_type === itemType);
  const mainMealCount = orderItems?.reduce(function (acc, curr) {
    return acc + curr?.meals?.length;
  }, 0);
  const totalMealCount = mainMealCount + subOrderCount;

  const isCutlery = orderDetails?.cutlery ?? true;

  useEffect(() => {
    if (finishOrder) return setPackagingComplete(true);
    const orderCompleted = selectedMeals?.length === totalMealCount;
    const isCutleryPacked = isCutlery ? cutleryChecked : true;
    const isPackagingCompleted = orderCompleted && isCutleryPacked;
    setPackagingComplete(isPackagingCompleted);
  }, [selectedMeals, cutleryChecked, finishOrder]);

  const handleSpeak = () => {
    speak({ text: order.order_code.split('').join(' ') + ' is getting late!' });
    setHasPlayedSound(true);
  };

  useEffect(() => {
    if (order?.kitchen_time_elapsed && !hasPlayedSound) {
      handleSpeak();
    }
  }, [order]);

  useEffect(() => {
    if (role === ENUM_KITCHEN_ROLE.PACKAGING) {
      setDisableReceipt(true);
    }
  }, []);

  const receiptRef = useRef(null);

  const getReceiptData = () => {
    return {
      status,
      calculatedOrder,
      isGroupOrder,
      orders: numberOfBrandAndMeal || orders,
      time,
      order,
      subOrders,
      isCutlery,
      discountValue,
      calculatedGroupTotal: calculatedGroupTotal(),
      subTotal,
      deliveryFee,
      serviceTotal,
      extraCharges,
      calculatedTotal,
      paid,
      amountDue,
      amountDueGroup,
      orderType,
      boxNumber
    };
  };

  const toggleAccordion = () => {
    setActiveState(setActive === '' ? 'activeAccordion' : '');
    setHeightState(setActive === 'activeAccordion' ? '0px' : `${content.current.scrollHeight}px`);
    setRotateState(setActive === 'activeAccordion' ? 'accordion__icon' : 'accordion__icon rotate');
  };

  const handleMealSelect = async (item) => {
    let newArr = [...selectedMeals, item];
    if (selectedMeals.includes(item)) {
      newArr = newArr.filter((itm) => itm !== item);
    }
    setSelectedMeals(newArr);
  };

  const handleAddonSelect = async (item) => {
    let newArr = [...selectedAddons, item];
    if (selectedAddons.includes(item)) {
      newArr = newArr.filter((itm) => itm !== item);
    }
    setSelectedAddons(newArr);
  };

  const handleBulkAddonSelect = async (items, checkAll) => {
    let newArr = [...selectedAddons];
    if (checkAll) {
      newArr = [...new Set([...newArr, ...items])];
    } else {
      newArr = newArr.filter((addon) => !items.includes(addon));
    }
    setSelectedAddons(newArr);
  };

  // calculated total for single order
  const calculatedTotal = parseInt(calculatedOrder?.total_amount);

  const serviceTotal = parseInt(calculatedOrder?.service_charge);
  const deliveryTotal =
    parseInt(calculatedOrder?.delivery_service_charge) + parseInt(calculatedOrder?.delivery_fee);
  const deliveryFee = parseInt(calculatedOrder?.free_delivery && '0') || deliveryTotal;

  const prevAmount = parseInt(calculatedOrder?.prev_price);
  const extraCharges = serviceTotal + deliveryFee;

  const subTotal = calculatedTotal - extraCharges;
  const discountValue =
    prevAmount && !calculatedOrder?.is_group_order ? prevAmount - calculatedTotal : 0;

  const walletAmount = orderDetails?.wallet_amount ? orderDetails?.wallet_amount : 0;

  const amountDue =
    !paid && walletAmount && orderDetails?.use_wallet
      ? calculatedTotal - parseInt(walletAmount)
      : !paid && !orderDetails?.use_wallet
      ? calculatedTotal - 0
      : 0;
  // calculated total for group order
  const calculatedGroupTotal = () => {
    return subTotal;
  };
  const amountDueGroup =
    !paid && walletAmount && orderDetails?.use_wallet
      ? calculatedGroupTotal() - +walletAmount
      : !paid && !orderDetails?.use_wallet
      ? calculatedGroupTotal() - 0
      : 0;

  // To reject an order
  const rejectOrder = async ({ value, reason }) => {
    const { rejectOrder } = homeStore;
    setRejectLoading(true);

    await rejectOrder(
      alert,
      order?.order_code,
      {
        cancelled_note: reason ? `${reason} and ${value}` : value
      },
      true
    );
    setRejectLoading(false);
    setShowRejectModal(false);
  };
  // get Staff NUmber
  if (order?.kitchen_time_elapsed && !hasPlayedSound && order?.order_code) {
    handleSpeak();
  }

  if (!show) {
    return <div></div>;
  }
  const showBoxAllocationAlert = () => {
    toast.success(`Order ${order.order_code.toUpperCase()} belongs to BOX ${boxNumber} `, {
      autoClose: 10000
    });
  };

  return (
    <CardLayout status={status} order={order}>
      {canManageSurge && <Surge order={order} />}
      <CardHeader
        order={order}
        calculatedOrder={calculatedOrder}
        paid={paid}
        walletAmount={walletAmount}
        isGroupOrder={isGroupOrder}
        actionClick={customActionFn}
        actionLoading={actionLoading}
        packagingComplete={packagingComplete}
        actionDisabled={actionDisabled}
        status={status}
        receiptRef={receiptRef}
        setShowInfoModal={setShowInfoModal}
        hideCardButton={hideCardButton}
        disableReceipt={disableReceipt}
      />
      {status === ENUM_STATUS.DISPATCH && (
        <BoxNumberSection
          status={status}
          order={order}
          calculated_order={calculatedOrder}
          time={time}
          setViewDetails={setViewDetails}
          viewDetails={viewDetails}
          reason={reason}
          riderAssigned={riderAssigned}
        />
      )}
      {(!viewDetails || status !== ENUM_STATUS.DISPATCH) && (
        <>
          <CardBody
            status={status}
            orders={orders}
            numberOfBrandAndMeal={numberOfBrandAndMeal}
            content={content}
            setHeight={setHeight}
            isGroupOrder={isGroupOrder}
            currentFilter={currentFilter}
            // new props
            cutleryChecked={cutleryChecked}
            isCutlery={isCutlery}
            calculatedOrder={calculatedOrder}
            order={order}
            onCheckCutlery={() => setCutleryChecked(!cutleryChecked)}
            onToggleAccordion={toggleAccordion}
            setActive={setActive}
            setRotate={setRotate}
            isFinished={finishOrder}
            onFinishOrder={() => setFinishOrder(!finishOrder)}
            subOrders={subOrders}
            selectedMeals={selectedMeals}
            selectedAddons={selectedAddons}
            onAddonSelect={handleAddonSelect}
            onBulkAddonSelect={handleBulkAddonSelect}
            onMealSelect={handleMealSelect}
            setViewDetails={setViewDetails}
            printStickerMeals={printStickerMeals}
            setPrintStickerMeals={setPrintStickerMeals}
          />

          <OrderReceipt ref={receiptRef} {...getReceiptData()} />

          <CardFooter
            status={status}
            order={order}
            time={time}
            role={role}
            riderAssigned={riderAssigned}
            reason={reason}
            receiptRef={receiptRef}
            setShowRejectModal={setShowRejectModal}
            printStickerMeals={printStickerMeals}
          />
          {showRejectModal && (
            <ConfirmRejectModal
              show={showRejectModal}
              loading={rejectLoading}
              onClose={() => setShowRejectModal(false)}
              onConfirm={rejectOrder}
            />
          )}
          {showInfoModal && (
            <UserInformationModal
              show={showInfoModal}
              onClose={() => setShowInfoModal(false)}
              order={order}
              isPreOrderPage={isPreOrderPage}
            />
          )}
        </>
      )}
    </CardLayout>
  );
});

const component = withAlert()(OrderCard);
export default React.memo(component, (prevProps, nextProps) => {
  return (
    prevProps.order.updated_at === nextProps.order.updated_at ||
    prevProps.itemType === nextProps.itemType1
  );
});
