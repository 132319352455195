import React, { FC, useEffect, useState } from 'react';
import { Input } from '@cokitchen/cokitchen-components';
import homeStore from '../../stores/homeStore';
import { OrderType } from '../../helpers/types';

type Props = {
  order: OrderType;
  showSuccess: (message: string) => void;
};

const OrderReady: FC<Props> = ({ order, showSuccess }) => {
  const [isReady, setIsReady] = useState(false);
  const { brandIsReady, activeHeaderBrands } = homeStore;
  const { order_code, kitchen_ready_brand_ids: activeBrands } = order;

  const listOfBrands = () => {
    if (activeHeaderBrands[0] === 'ALL') {
      const meals = order.calculated_order?.meals ?? [];
      const kitchen_ready_brand_ids = meals.map(({ brand }) => brand.id);
      return { kitchen_ready_brand_ids };
    }

    return { kitchen_ready_brand_ids: activeHeaderBrands };
  };

  const updateBrandReady = async () => {
    const is_ready = !isReady;
    try {
      setIsReady(is_ready);
      const res = await brandIsReady({
        order_code,
        is_ready,
        ...listOfBrands()
      });
      showSuccess(res.message as string);
    } catch (error) {
      setIsReady(!is_ready);
    }
  };

  useEffect(() => {
    if (activeBrands !== undefined) {
      const meals = order.calculated_order?.meals ?? [];
      const allActiveBrands = meals.map(({ brand }) => brand.id);
      const allBrandsAreActive = allActiveBrands.every((brand) => activeBrands.includes(brand));
      setIsReady(allBrandsAreActive);
    }
  }, [activeBrands]);

  return (
    <Input
      label="Ready"
      type="checkbox"
      checked={isReady}
      checkColor={isReady ? 'green' : 'darkgray'}
      className={`rounded-full bg-white px-[29.5px] py-[7px] uppercase ${
        isReady ? 'text-green-5' : 'text-gray-6'
      }`}
      onChange={updateBrandReady}
    />
  );
};

export default OrderReady;
