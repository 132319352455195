import React, { useContext } from 'react';
import { FilterType } from '../../pages/live/filters';
import { OrderType } from '../../helpers/types';

type LayoutContextType = {
  pinnedOrders: OrderType[];
  setPinnedOrders: (item: OrderType[]) => void;
  activePinFilter: FilterType | null;
  setActivePinFilter: (item: FilterType | null) => void;
  testMode: boolean;
  setTestMode: (show: boolean) => void;
  deliveryType: string;
  setDeliveryType: (type: string) => void;
  setReceiptToPrint: (arg0: { ref: any; shouldPrint: boolean; order: OrderType | null }) => void;
  receiptToPrint: { ref: any; shouldPrint: boolean; order: OrderType | null };
};
export const LayoutContext = React.createContext<LayoutContextType>({
  pinnedOrders: [],
  setPinnedOrders: () => {},
  activePinFilter: null,
  setActivePinFilter: () => {},
  testMode: false,
  setTestMode: () => {},
  deliveryType: '',
  setDeliveryType: () => {},
  receiptToPrint: {
    ref: null,
    shouldPrint: false,
    order: null
  },
  setReceiptToPrint: () => {}
});
const useLayoutContext = (): LayoutContextType => {
  const context = useContext(LayoutContext);
  return context;
};

export default useLayoutContext;
