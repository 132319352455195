import React, { FC, ReactNode, useEffect, useState } from 'react';
import { LayoutContext } from './context';
import { FilterType } from '../../pages/live/filters';
import { OrderType } from '../../helpers/types';
import { STORAGE_KEYS } from '../../helpers/constants';

const LayoutProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const defaultDeliveryType = localStorage.getItem(STORAGE_KEYS.DELIVERY_TYPE) ?? '';
  const [pinnedOrders, setPinnedOrders] = useState([] as OrderType[]);
  const [activePinFilter, setActivePinFilter] = useState<FilterType | null>(null);
  const [testMode, setTestMode] = useState(
    JSON.parse(localStorage.getItem(STORAGE_KEYS.TEST_MODE) ?? 'false')
  );
  const [deliveryType, setDeliveryType] = useState(
    defaultDeliveryType === '' ? '' : JSON.parse(defaultDeliveryType)
  );
  const [receiptToPrint, setReceiptToPrint] = useState<{
    ref: any;
    shouldPrint: boolean;
    order: OrderType | null;
  }>({
    ref: null,
    shouldPrint: false,
    order: null
  });

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.TEST_MODE, JSON.stringify(testMode));
  }, [testMode]);
  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.DELIVERY_TYPE, JSON.stringify(deliveryType));
  }, [deliveryType]);
  return (
    <LayoutContext.Provider
      value={{
        pinnedOrders,
        setPinnedOrders,
        activePinFilter,
        setActivePinFilter,
        testMode,
        setTestMode,
        deliveryType,
        setDeliveryType,
        receiptToPrint,
        setReceiptToPrint
      }}>
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
