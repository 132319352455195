import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import { BsCheckCircle, BsInfoCircle } from 'react-icons/bs';
import { BiErrorCircle } from 'react-icons/bi';
import Login from './pages/login';
import UnauthRoute from './router/UnauthRoute';
import { observer } from 'mobx-react-lite';
import { ToastContainer } from 'react-toastify';
import {
  adminRoutes,
  frontdeskRoutes,
  kitchenRoutes,
  packagingRoutes,
  shopRoutes,
  superAdminRoutes
} from './router';
import ProtectedRoute from './router/ProtectedRoute';
import { ROUTE_KEYS } from './helpers/constants';
import ResetPassword from './pages/reset-password';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';

// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 10000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.FADE
};

const AlertTemplate = observer(function AlertTemplate({ style, options, message, close }) {
  return (
    <div
      style={style}
      className={`app_alert ${
        options.type === 'info'
          ? 'app_alert_info'
          : options.type === 'success'
          ? 'app_alert_success'
          : options.type === 'error'
          ? 'app_alert_error'
          : ''
      }`}>
      <span>
        {options.type === 'info' && <BsInfoCircle />}
        {options.type === 'success' && <BsCheckCircle />}
        {options.type === 'error' && <BiErrorCircle />}
      </span>
      {message}
      <button onClick={close}></button>
    </div>
  );
});

ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...options}>
    <React.StrictMode>
      <Router>
        <Switch>
          <UnauthRoute exact path="/" component={App} />
          {/* Auth */}
          <UnauthRoute exact path={ROUTE_KEYS.LOGIN} component={Login} />
          <UnauthRoute exact path={ROUTE_KEYS.RESET_PASSWORD} component={ResetPassword} />
          {/* Kitchen */}
          {kitchenRoutes.map(({ route, component }, i) => (
            <ProtectedRoute
              exact
              panelRole={[ENUM_KITCHEN_ROLE.KITCHEN_STAFF, ENUM_KITCHEN_ROLE.FCB_KITCHEN_STAFF]}
              key={i}
              path={route}
              component={component}
            />
          ))}
          {/* Front desk */}
          {frontdeskRoutes.map(({ route, component }, i) => (
            <ProtectedRoute
              exact
              key={i}
              panelRole={ENUM_KITCHEN_ROLE.FRONT_DESK}
              path={route}
              component={component}
              allowAdmin
            />
          ))}
          {/* PackagingRoute */}
          {packagingRoutes.map(({ route, component }, i) => (
            <ProtectedRoute
              exact
              panelRole={[ENUM_KITCHEN_ROLE.PACKAGING, ENUM_KITCHEN_ROLE.FCB_PACKAGING_STAFF]}
              key={i}
              path={route}
              component={component}
            />
          ))}
          {/* Shop routes */}
          {shopRoutes.map(({ route, component }, i) => (
            <ProtectedRoute
              exact
              panelRole={ENUM_KITCHEN_ROLE.SHOP_STAFF}
              key={i}
              path={route}
              component={component}
            />
          ))}
          {/* Admin routes */}
          {adminRoutes.map(({ route, component }, i) => (
            <ProtectedRoute
              exact
              panelRole={[
                ENUM_KITCHEN_ROLE.KITCHEN_ADMIN,
                ENUM_KITCHEN_ROLE.FCB_KITCHEN_SUPERVISOR
              ]}
              key={i}
              path={route}
              component={component}
            />
          ))}
          {/* Super admin routes */}
          {superAdminRoutes.map(({ route, component }, i) => (
            <ProtectedRoute
              exact
              panelRole={[ENUM_KITCHEN_ROLE.SUPER_ADMIN, ENUM_KITCHEN_ROLE.DEVELOPER]}
              key={i}
              path={route}
              component={component}
            />
          ))}
          {/* Redirect for backward compatibilty */}
          <Redirect to={ROUTE_KEYS.LOGIN} />
        </Switch>
      </Router>
      <ToastContainer />
    </React.StrictMode>
  </AlertProvider>,
  document.getElementById('root')
);
reportWebVitals();
